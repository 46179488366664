<template>
  <v-data-table
      class='elevation-1'
      :footer-props='footerProps'
      :headers='headers'
      :items='exams'
      item-key='Id'
      :loading='loading'
      :sort-by.sync='pageSortBy'
      sort-desc.sync='true'
  >
    <template v-slot:item.AnsweredDate="{ item }">
      {{ item.AnsweredDate | FormatDateISO }}
    </template>
    <template v-slot:item.actions="{ item }">
      <button
          class='linkBtn'
          type='button'
          v-show='allowEdit'
          @click='editDlg(item)'
      >
        Edit
      </button>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: ['exams', 'loading', 'allowEdit'],
  data() {
    return {
      footerProps: {itemsPerPageOptions: [25, 50]},
      pageSortBy: 'AnsweredDate',
      headers: [
        {
          text: 'Date',
          value: 'AnsweredDate',
          sortable: true,
          width: '5%'
        },
        {text: 'Height', value: 'Height', sortable: true, width: '5%'},
        {text: 'Weight (Kg)', value: 'Weight', sortable: true, width: '5%'},
        {text: 'BMI', value: 'BMI', sortable: true, width: '5%'},
        {
          text: 'Pulse Rate (bpm)',
          value: 'PR',
          sortable: true,
          width: '5%'
        },
        {
          text: 'Blood Pressure (mmHg)',
          value: 'BP',
          sortable: true,
          width: '5%'
        },
        {
          text: 'Temperature (Celsius)',
          value: 'Temp',
          sortable: true,
          width: '5%'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          width: '5%',
          align: 'center'
        }
      ]
    };
  },
  methods: {
    editDlg(item) {
      this.$emit('edit', item);
    }
  }
};
</script>

<style scoped></style>
