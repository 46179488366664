<template>
  <v-container grid-list-xl fluid>
    <v-layout row wrap>
      <v-flex sm6>
        <div class='headline green--text text--darken-4 font-weight-medium'>
          Patient Examination Details
        </div>
      </v-flex>
      <v-flex xs12 sm12 lg12>
        <v-btn color='primary' v-show='allowAdd' @click='createDlg'>
          <v-icon class='iconText'>fa fa-plus</v-icon>
          New Examination
        </v-btn>
      </v-flex>
      <v-flex xs12 sm12 lg12>
        <v-layout row wrap>
          <div class='filterStyle'>
            <v-text-field label='Patient Id:' v-model='patientId'>
              <template slot='prepend'>
                <v-icon class='filterIcon grey--text text--darken-1'
                  >fa fa-filter</v-icon
                >
              </template>
            </v-text-field>
          </div>
          <v-flex>
            <v-btn :disabled='loading' @click='searchPatient'>
              <v-icon class='iconText'>fa fa-search</v-icon>
              Search
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs6 sm6 lg6 v-show='patient.Id'>
        <PatientCard :patient='patient'></PatientCard>
      </v-flex>
      <v-flex xs12 sm12 lg12>
        <v-card>
          <v-card-text class='pa-0'>
            <ExamsDisplay
              :exams='exams'
              :loading='loading'
              :allow-edit='allowEdit'
              @edit='editDlg'
            ></ExamsDisplay>
            <ExamDlg
              :pid='patient.Id'
              :dlg-exam='dlgShow'
              :dlg-type='dlgType'
              :obj='selectedItem'
              @close='closeDlg'
              @add='addNewExam'
            >
            </ExamDlg>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import ExamDlg from '../../components/exam/ExamDlg';
import ExamsDisplay from '../../components/exam/ExamsDisplay';
import PatientCard from '../../components/patients/PatientCard';
import WebH from '../../api/web';
export default {
  components: {
    PatientCard,
    ExamsDisplay,
    ExamDlg
  },
  created() {
    this.allowAdd = this.$store.getters['UserStore/permissions'].includes(
      'ex.a'
    );
    this.allowEdit = this.$store.getters['UserStore/permissions'].includes(
      'ex.e'
    );
    this.patientId = this.$store.getters['SettingsStore/patientId'];
    this.searchPatient();
  },
  data() {
    return {
      allowAdd: false,
      allowEdit: false,
      loading: false,
      exams: [],
      patientId: '',
      patient: {},
      dlgShow: false,
      dlgType: 0,
      selectedItem: null
    };
  },
  methods: {
    closeDlg() {
      this.selectedItem = null;
      this.dlgShow = false;
    },
    // viewDlg(item) {
    //   this.selectedItem = Object.assign({}, item);
    //   this.dlgType = 2;
    //   this.dlgShow = true;
    // },
    editDlg(item) {
      this.selectedItem = Object.assign({}, item);
      this.dlgType = 1;
      this.dlgShow = true;
    },
    createDlg() {
      this.selectedItem = null;
      this.dlgType = 0;
      this.dlgShow = true;
    },
    addNewExam(exam, edit) {
      const height = exam.Height / 100;
      exam.BMI = Number(exam.Weight / (height * height)).toFixed(1);
      if(edit) {
        const index = this.exams.findIndex(x=>x.Id === exam.Id);
        this.exams.splice(index,1, exam);
      }
      else {
        this.exams.push(exam);
      }
      this.$notify({
        type: 'info',
        text: 'Successfully added new examination'
      });
    },
    // async deleteDlg(item) {
    //   const res = await this.$notifyDlg.show(
    //     'Do you really want delete this patient?',
    //     'Delete patient',
    //     'warning',
    //     true
    //   );
    //   if (res) {
    //     this.api_delete(item);
    //   }
    // },
    async searchPatient() {
      if (this.patientId === '') {
        return;
      }
      this.$store.commit('SettingsStore/setPatientId', this.patientId);
      this.loading = true;
      this.patient = {};
      this.exams = [];
      let url = '/patients/search?&name=&pid=' + this.patientId + '&mobile=';
      const res = await WebH.Get(url, 'patients');
      if (res.Success) {
        if (res.Data.length > 0) {
          this.patient = res.Data[0];
          await this.getRelatedData();
        } else {
          await this.$notifyDlg.show('Invalid patient id', 'Error', 'error');
        }
      }
      this.loading = false;
    },
    async getRelatedData() {
      if (this.patient === null || this.patient.Id === null) {
        await this.$notifyDlg.show('Invalid patient id', 'Error', 'error');
        return;
      }
      this.loading = true;
      this.exams = [];
      let url = '/exams/' + this.patient.Id + '/all';
      const res = await WebH.Get(url, 'examinations');
      if (res.Success) {
        this.exams = res.Data;
      }
      this.loading = false;
    }
    // async api_delete(data) {
    //   const res = await WebH.Delete('/patients/' + data.Id);
    //   if (res.Success) {
    //     const index = this.patients.findIndex(data);
    //     if (index) {
    //       this.patients.splice(index, 1);
    //     }
    //     this.$notify({
    //       type: 'info',
    //       text: 'Successfully deleted the patient'
    //     });
    //   } else {
    //     await this.$notifyDlg.show(res.Data, 'Error', 'error');
    //   }
    // }
  }
};
</script>

<style scoped></style>
